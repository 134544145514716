import React, {useState} from 'react';
import {connect} from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import TermDialog from '../../../app/components/other/Dialog/TermDialog';
import {LayoutContextConsumer} from '../LayoutContext';
import {useIntl} from "react-intl";

const Footer = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');
  const intl = useIntl();

  const handleDialogOpen = contentType => {
    setDialogContentType(contentType);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <LayoutContextConsumer>
      {({footer: {show}}) =>
        // show && (
          <div style={{
            width: '100%',
           // backgroundColor: '#ededed'
          }}>
          <div
            className={`kt-footer ${props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
            id='kt_footer'
            style={{
             // backgroundColor: '#ededed',
              maxWidth: 1440,
              margin: '0 auto',
              width: '100%'
            }}
          >
            <div className={`kt-container ${props.footerContainerClasses}`}>
              <div className='kt-footer__copyright'>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div>
                  <div>
                  <a
                    rel='noopener noreferrer'
                    className='kt-link'
                    onClick={() => handleDialogOpen('user_agreement')}
                  >
                    {intl.formatMessage({ id: 'SUBMENU.LEGAL_TERMS' })}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('privacy_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    {intl.formatMessage({ id: 'SUBMENU.LEGAL_PRIVACY' })}
                  </a>
                  {/* &nbsp;&nbsp;|&nbsp;&nbsp; */}
                  </div>
                  <a
                    onClick={() => handleDialogOpen('shipping_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    {intl.formatMessage({ id: 'SUBMENU.SHIPPING' })}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('refund_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    {intl.formatMessage({ id: 'FOOTER.COPYRIGHT.RETURN' })}
                  </a>
                  <br/>
                  <br/>
                  {intl.formatMessage({ id: 'FOOTER.COPYRIGHT.DEVELOPMENT' })}:&nbsp;
                  <a
                    href='https://start-mobile.net'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    Start Mobile
                  </a>
                </div>
                {/*<div className='kt-footer__menu'></div>*/}
                <>
                  <TermDialog
                    isOpen={openDialog}
                    handleClose={handleDialogClose}
                    contentType={dialogContentType}
                  />
                </>
              </div>
              </div>
              <p className='kt-footer__copyright' >
                CS Medicus s.r.o.
                <br/>
                ičo 10890599
                <br/>
                Thámova 221/7
                <br/>
                18600 Praha-8, Karlín
              </p>
            </div>
          </div>
          </div>
        // )
      }
    </LayoutContextConsumer>
  );
}

const mapStateToProps = store => ({
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
