import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesReviewsList = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 10,
  },

  textTotalRating: {
    fontStyle: 'bold',
    fontWeight: 600,
    color: '#000',
    marginBottom: 30,
    textAlign: 'center',
  },

  wrapperBlock: {
    marginBottom: '2.25rem',
  },

  wrapperUserInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    flexWrap: 'wrap',
  },

  wrapperUserAvatar: {
    display: 'inline-block',
    flexShrink: 0,
    position: 'relative',
    borderRadius: '.475rem',
    marginRight: '1.25rem',
  },

  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: '0.475rem',
  },

  userFio: {
    fontWeight: 600,
    fontSize: '1.15rem',
    wordWrap: 'break-word',
    color: '#3f4254',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  reviewText: {
    margin: 0,
    fontWeight: 400,
    fontSize: '1.15rem',
    color: '#5e6278',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  wrapperPaginator: {
    display: 'flex',
    justifyContent: 'center',
  },

  titleContent: {
    display: 'flex',
    width:'100%',
    flexDirection:'column'
  },

  ratingContent: {
    display:'flex',
    justifyContent:'flex-start',
    marginTop: '20px'
  },

  reviewContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  }
}));

export const useStylesProductCatalog = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    productCategory: {
      flex:1,
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      maxWidth: '300px',
      minWidth: '260px',
      height: '540px',
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',
      '&:hover': {
        transform: 'scale(1.03)',
      },
      backgroundColor: 'transparent',
      // [theme.breakpoints.up('md')]: {
      //   width: 176,
      // },
        [theme.breakpoints.down('sm')]: {
            minWidth: 136,
        },
    },
    category_photo: {
      flex: 1,
    },
    category_button: {
      height: 90,
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    category_button_text: {
      fontSize: 24,
      fontWeight: 400,
      color: '#441861',
      padding: '0 30px',
      textAlign: 'end'
    },
    position_title_img: {
      right: 0,
    },
    title_img: {
      fontSize: 64,
      fontWeight: 400,
      color: '#fff',
      position: "absolute",
      transform: 'rotate(180deg)',
      writingMode: 'vertical-lr',
      margin: 15,
      textAlign: 'end',
        [theme.breakpoints.down(540)]: {
            fontSize: 44,
        },
    },
    position_text: {
      textAlign: 'start',
    },
    productCard: {
      flex:1,
      maxWidth: '365px',
      height: '450px',
      padding: 12,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',
      '&:hover': {
        transform: 'scale(1.03)',
      },

      [theme.breakpoints.up('md')]: {
        width: 176,
      },
    },
    reverse: {
      flexDirection: 'row-reverse'
    },
    containerProducts: {
      display: 'flex',
      gap: 15,
      marginBottom: 15,
      flexWrap: "wrap",
      // gridGap: 19,
      // gridTemplateColumns: 'minmax(365px,1fr) minmax(365px,1fr) minmax(365px,1fr) minmax(365px,1fr);',

        [theme.breakpoints.down(770)]: {
            display: 'grid',
            gridGap: 19,
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      // [theme.breakpoints.down('sm')]: {
      //   gridTemplateColumns: 'repeat(1, 1fr)',
      // },
      // [theme.breakpoints.up('sm')]: {
      //   gridTemplateColumns: 'repeat(2, 1fr)',
      // },
      // [theme.breakpoints.up('md')]: {
      //   gridTemplateColumns: 'repeat(3, 1fr)',
      // },
      // [theme.breakpoints.up('lg')]: {
      //   gridTemplateColumns: 'repeat(3, 1fr)',
      // },
      // [theme.breakpoints.up('xl')]: {
      //   gridTemplateColumns: 'repeat(3, 1fr)',
      // },
    },
    container2: {
      display: 'flex',
      flexDirection: 'row',
      // paddingTop: 65,
      justifyContent: 'space-between',
      // flexWrap: 'wrap',
      gap: 30,
      width: '100%',
      maxWidth: '1440px',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 30,
      },
      [theme.breakpoints.down('lg')]: {
        gap: 30,
      },
    },
    container_wrap: {
      maxWidth: '1220px',
      margin: '0 auto',
      width: '80% !important',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        padding: '0 15px',
      },
    },
    wrapContent: {
      // width: 'calc(100% - 36px)',
      // [theme.breakpoints.down('sm')]: {
      //   width: '100%',
      // },
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        // marginLeft: theme.spacing(0.6),
      },
      // [theme.breakpoints.up('sm')]: {
      //   width: '95%',
      //   // marginLeft: theme.spacing(1.3),
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: '79%',
      //   // marginLeft: theme.spacing(8.5),
      // },
      // [theme.breakpoints.up('lg')]: {
      //   width: '75%',
      //   // marginLeft: theme.spacing(20.5),
      // },
      // [theme.breakpoints.up('xl')]: {
      //   width: '70%',
      //   marginRight: theme.spacing(5),
      // },
      // // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   width: '68.5%',
      //   marginLeft: theme.spacing(7),
      // },
    },
    wrap_content: {
      width: 'calc(100% )',
    },
    wrap_content_bottom: {
      width: 'calc(100%)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      paddingTop: 10,
      background: 'rgba(54,159,247,0.1)',
    },
    input: {
      width: '100%',
      padding: '14.5px 14px',
      borderWidth: 0,
      borderRadius: 4,
      outline: 'none',
      boxSizing: 'border-box',
    },
    inputBuyer: {
      width: '100%',
      padding: '14.5px 14px',
      borderWidth: 0,
      borderRadius: 4,
      outline: 'none',
      boxSizing: 'border-box',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
      color: 'rgb(0, 90, 90)',
    },
    card: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: '100%',

      // [theme.breakpoints.down('sm')]: {
      //   width: '100%',
      //   marginLeft: theme.spacing(0.6),
      // },
      // [theme.breakpoints.up('sm')]: {
      //   width: '97%',
      //   marginLeft: theme.spacing(1.3),
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: '83%',
      //   marginLeft: theme.spacing(10),
      // },
      // [theme.breakpoints.up('lg')]: {
      //   width: '78.5%',
      //   marginLeft: theme.spacing(18.2),
      // },
      // [theme.breakpoints.up('xl')]: {
      //   width: '72.5%',
      //   marginLeft: theme.spacing(21.5),
      // },
      // // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   width: '70%',
      //   marginLeft: theme.spacing(35.5),
      // },
    },
    card2: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: theme.spacing(0.6),
      },
      [theme.breakpoints.up('sm')]: {
        width: '97%',
        marginLeft: theme.spacing(1.3),
      },
      [theme.breakpoints.up('md')]: {
        width: '83%',
        marginLeft: theme.spacing(10),
      },
      [theme.breakpoints.up('lg')]: {
        width: '77%',
        marginLeft: theme.spacing(20),
      },
      [theme.breakpoints.up('xl')]: {
        width: '70%',
        marginLeft: theme.spacing(26),
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        width: '70%',
        marginLeft: theme.spacing(35),
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 700,
      color: '#212328',
      marginRight: 10,
      fontSize: 32,
      marginBottom: 24,
    },
      titleGrid: {
      fontWeight: 300,
      color: '#000',
      marginRight: 10,
      fontSize: 34,
      marginBottom: 24,
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        width: 326,
      },
    },
    productsCol: {
      // [theme.breakpoints.up('sm')]: {
      //   width: 'calc(100% - 30px)',
      // },
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 24,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    searchField: {
      width: 300,
      margin: 0,
      marginLeft: 30,
      '& .MuiOutlinedInput-root': {
        height: 48,
      },
    },
    searchBtn: {
      marginLeft: 30,
    },
    categoriesList: {
      width: 'calc(75% - 10px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    labelText: {
      fontWeight: 500,
      flexGrow: 1,
      fontSize: 14,
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    filtersDisplay: {
      display: 'none',

      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    category_content: {
      width: 'calc(100%)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    category_card: {
      position: 'relative',
      paddingTop: 24,
      width: '100%',
      // [theme.breakpoints.down('sm')]: {
      // },
      // [theme.breakpoints.up('sm')]: {
      //   width: '93%',
      //   marginLeft: theme.spacing(1.3),
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: '77%',
      //   marginLeft: theme.spacing(1),
      // },
      // [theme.breakpoints.up('lg')]: {
      //   width: '72%',
      //   marginLeft: theme.spacing(1),
      // },
      // [theme.breakpoints.up('xl')]: {
      //   width: '68%',
      //   marginRight: theme.spacing(6),
      // },
      // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   width: '67%',
      //   marginLeft: theme.spacing(8),
      // },
    },
    card1elm: {
      marginBottom: 48,
      width: '100%'
    },
    // card2elm: {
    //   marginTop: theme.spacing(4),
    //   marginBottom: theme.spacing(4),
    //
    //   [theme.breakpoints.down('sm')]: {
    //     width: '100%',
    //     marginLeft: theme.spacing(0.6),
    //   },
    //   [theme.breakpoints.up('sm')]: {
    //     width: '100%',
    //     marginLeft: theme.spacing(1.3),
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     width: '84%',
    //     marginLeft: theme.spacing(10),
    //   },
    //   [theme.breakpoints.up('lg')]: {
    //     width: '46%',
    //     marginLeft: theme.spacing(18.2),
    //   },
    //   [theme.breakpoints.up('xl')]: {
    //     width: '35%',
    //     marginLeft: theme.spacing(21.5),
    //   },
    //   // @ts-ignore
    //   [theme.breakpoints.up('xxl')]: {
    //     width: '30%',
    //     marginLeft: theme.spacing(35.5),
    //   },
    // },
    // card3elm: {
    //   marginTop: theme.spacing(4),
    //   marginBottom: theme.spacing(4),
    //
    //   [theme.breakpoints.down('sm')]: {
    //     width: '100%',
    //     marginLeft: theme.spacing(0.6),
    //   },
    //   [theme.breakpoints.up('sm')]: {
    //     width: '97%',
    //     marginLeft: theme.spacing(1.3),
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     width: '84%',
    //     marginLeft: theme.spacing(10),
    //   },
    //   [theme.breakpoints.up('lg')]: {
    //     width: '57.7%',
    //     marginLeft: theme.spacing(18.2),
    //   },
    //   [theme.breakpoints.up('xl')]: {
    //     width: '52%',
    //     marginLeft: theme.spacing(21.5),
    //   },
    //   // @ts-ignore
    //   [theme.breakpoints.up('xxl')]: {
    //     width: '42%',
    //     marginLeft: theme.spacing(35.5),
    //   },
    // },
    wrap_blog_content: {
      width: 'calc(100% - 10px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    blog_card: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      margin: '0 auto',
      // [theme.breakpoints.down('sm')]: {
      //   width: '100%',
      //   marginLeft: theme.spacing(0.6),
      // },
      // [theme.breakpoints.up('sm')]: {
      //   width: '98%',
      //   marginLeft: theme.spacing(1.3),
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: '83.5%',
      //   marginLeft: theme.spacing(8.5),
      // },
      // [theme.breakpoints.up('lg')]: {
      //   width: '79.5%',
      //   marginLeft: theme.spacing(18.2),
      // },
      // [theme.breakpoints.up('xl')]: {
      //   width: '73.5%',
      //   marginLeft: theme.spacing(21),
      // },
      // // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   width: '70.4%',
      //   marginLeft: theme.spacing(35.2),
      // },
    },
    card_banner: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),

    },
    titleCategory: {
      fontStyle: 'bold',
      fontWeight: 700,
      color: '#000',
      marginRight: 15,
      fontSize: 30,
      left: -15,
      position: 'absolute',
      top: 0,

      [theme.breakpoints.up('xl')]: {
        left: -10,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        left: -17,
      },
      }
  })
);
